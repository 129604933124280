import { createIcon } from "@chakra-ui/icons";

export const SyncIcon = createIcon({
  displayName: "SyncIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.87774 6.40004C8.87774 8.26671 7.33886 9.7797 5.43887 9.7797C3.53999 9.7797 2 8.26671 2 6.40004C2 4.53447 3.53999 3.02148 5.43887 3.02148C7.33886 3.02148 8.87774 4.53447 8.87774 6.40004ZM20.4933 4.91981C21.3244 4.91981 22 5.58351 22 6.40004C22 7.21767 21.3244 7.88137 20.4933 7.88137H13.9178C13.0856 7.88137 12.4101 7.21767 12.4101 6.40004C12.4101 5.58351 13.0856 4.91981 13.9178 4.91981H20.4933ZM3.50777 15.9794H10.0833C10.9155 15.9794 11.5911 16.6431 11.5911 17.4608C11.5911 18.2773 10.9155 18.9421 10.0833 18.9421H3.50777C2.67555 18.9421 2 18.2773 2 17.4608C2 16.6431 2.67555 15.9794 3.50777 15.9794ZM18.5611 20.7993C20.4611 20.7993 22 19.2863 22 17.4207C22 15.554 20.4611 14.041 18.5611 14.041C16.6623 14.041 15.1223 15.554 15.1223 17.4207C15.1223 19.2863 16.6623 20.7993 18.5611 20.7993Z"
    />
  ),
});
