import { Text } from "@chakra-ui/react";

import { PylonLink } from "ui/pylon";

export const NoCommonErrors = ({ source = false}) => (
  <Text textStyle="paragraph">
  To date, our customers haven't experienced any errors while using this {source ? "source" : "destination"}. If you run into any issues, please don't hesitate to{" "}
    <PylonLink pylonText="" linkText="reach out" />.   
  We're here to help.
  </Text>
);
