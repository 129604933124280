import {
  Box,
  Flex,
  Text,
  AspectRatio,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import Image from "next/image";
import { FC, useState } from "react";

import posterImage from "../../../public/images/demo-embed/poster.png";

import { track } from "lib/analytics";
import { Heading } from "ui";

interface DemoEmbedProps {
  /**
   * H2 heading.
   * Just like the entire component, this is hidden on mobile and tablet screens.
   */
  title?: string;
  /**
   * Brief and optional description.
   * Just like the entire component, this is hidden on mobile and tablet screens.
   */
  description?: string;
  /**
   * Navattic embed id.
   * @default: clinphnj3003g08kxhoo7dg8s
   */
  demoId?: string;
  /**
   * Name used in analytics event data.
   * @default: Ungated master
   */
  demoName?: string;
}

/**
 * DemoEmbed is only visible on web screens, i.e. hidden on mobile (sm) and tablet (md) screens.
 * Screen-size visibility mirrors that for our app, since it's an interactive app-walkthrough.
 */
export const DemoEmbed: FC<Readonly<DemoEmbedProps>> = ({
  title,
  description,
  demoId = "clinphnj3003g08kxhoo7dg8s",
  demoName = "Ungated master",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = useState<boolean>(false);

  const openModalClicked = () => {
    setLoading(true);
    track("Demo Embed Opened", {
      Demo: demoId,
      Name: demoName,
      page: window.location.href,
    });
    onOpen();
  };

  const closeModalClicked = () => {
    track("Demo Embed Closed", {
      Demo: demoId,
      Name: demoName,
      page: window.location.href,
    });
    onClose();
  };

  return (
    <Box display={{ base: "none", lg: "block" }} pos="relative" my={8} w="100%">
      {title && <Heading as="h2">{title}</Heading>}

      {description && <Text textStyle="paragraph">{description}</Text>}

      <Flex
        display={{ base: "none", lg: "flex" }}
        pos="relative"
        align="start"
        my={8}
        w="100%"
        maxW="4xl"
        mx="auto"
      >
        <Flex w="100%" align="center" justify="center">
          <AspectRatio pos="relative" ratio={15 / 8} w="100%" borderRadius="md">
            <Image
              src={posterImage}
              alt="Hightouch Audiences user interface."
              layout="fill"
            />
          </AspectRatio>

          <Button variant="grass" pos="absolute" onClick={openModalClicked}>
            Start exploring
          </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
          <Box pos="relative" zIndex={10}>
            <ModalOverlay bg="white" />

            <ModalContent>
              <ModalHeader display="flex" py={3} px={5} justifyContent="end">
                <ModalCloseButton onClick={closeModalClicked} />
              </ModalHeader>

              <Flex
                pos="relative"
                align="center"
                justify="center"
                w="100%"
                h="90vh"
                px={12}
              >
                {isLoading && (
                  <Spinner
                    boxSize={9}
                    thickness="6px"
                    emptyColor="base.border"
                    color="forest"
                    speed="0.65s"
                    pos="absolute"
                    zIndex={11}
                  />
                )}

                <Box
                  as="iframe"
                  src={`https://capture.navattic.com/${demoId}`}
                  allow="fullscreen"
                  borderRadius="md"
                  w="100%"
                  h="100%"
                  objectFit="contain"
                  onLoad={() => setLoading(false)}
                />
              </Flex>
            </ModalContent>
          </Box>
        </Modal>
      </Flex>
    </Box>
  );
};
