import { Center, Flex, Input } from "@chakra-ui/react";
import { KBarPortal, KBarPositioner, KBarAnimator, KBarResults } from "kbar";
import { VFC } from "react";

import { Hits } from "components/commandbar/search/hits";
import { SearchInput } from "components/commandbar/search/search-input";

export const Commandbar: VFC = () => {
  return (
    <KBarPortal>
      <Center
        position="fixed"
        width="100%"
        height="100%"
        top={0}
        left={0}
        bg="rgba(30,30,46,.3)"
        zIndex={10}
      >
        <KBarPositioner>
          <KBarAnimator style={{ maxWidth: "600px", width: "100%" }}>
            <Flex
              direction="column"
              bg="white"
              borderRadius="4px"
              overflow="hidden"
            >
              <SearchInput />
              <Hits />
            </Flex>
          </KBarAnimator>
        </KBarPositioner>
      </Center>
    </KBarPortal>
  );
};
