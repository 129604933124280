import { createIcon } from "@chakra-ui/react";

export const SparkleIcon = createIcon({
  displayName: "SparkleIcon",
  viewBox: "0 0 24 25",
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M17 3.75a1 1 0 0 1 1 1c0 .257.13.577.402.848.271.271.59.402.848.402a1 1 0 1 1 0 2c-.257 0-.577.13-.848.402-.271.271-.402.59-.402.848a1 1 0 1 1-2 0c0-.257-.13-.577-.402-.848-.271-.271-.59-.402-.848-.402a1 1 0 1 1 0-2c.257 0 .577-.13.848-.402.271-.271.402-.59.402-.848a1 1 0 0 1 1-1Zm0 10a1 1 0 0 1 1 1c0 .257.13.577.402.848.271.271.59.402.848.402a1 1 0 1 1 0 2c-.257 0-.577.13-.848.402-.271.271-.402.59-.402.848a1 1 0 1 1-2 0c0-.257-.13-.577-.402-.848-.271-.271-.59-.402-.848-.402a1 1 0 1 1 0-2c.257 0 .577-.13.848-.402.271-.271.402-.59.402-.848a1 1 0 0 1 1-1Zm-8-7a1 1 0 0 1 1 1c0 .767.376 1.587 1.02 2.23.643.644 1.463 1.02 2.23 1.02a1 1 0 1 1 0 2c-.767 0-1.587.376-2.23 1.02-.644.643-1.02 1.463-1.02 2.23a1 1 0 1 1-2 0c0-.767-.376-1.587-1.02-2.23C6.337 13.376 5.517 13 4.75 13a1 1 0 1 1 0-2c.767 0 1.587-.376 2.23-1.02C7.624 9.337 8 8.517 8 7.75a1 1 0 0 1 1-1Zm0 3.934A5.859 5.859 0 0 1 7.684 12 5.86 5.86 0 0 1 9 13.316 5.867 5.867 0 0 1 10.316 12 5.871 5.871 0 0 1 9 10.684Z"
        clipRule="evenodd"
      />
    </svg>
  ),
});
