import { Info } from "../alert";
import { Link } from "../link";

export const PremiumDestination = ({ destinationName = "This destination"}) => (
  <Info>
    {destinationName} is a{" "}
    <Link
        textDecoration="underline"
        color="grass"
        href={"/pricing/ss-pricing#premium-destinations"}
    >
      premium destination{" "}
    </Link>
    and is only available on{" "}
    <Link
        textDecoration="underline"
        color="grass"
        href={"/security/networking/#ip-addresses"}
    >
      Business tier plans
    </Link>
    .
  </Info>
);
