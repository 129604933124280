import { ChakraProvider } from "@chakra-ui/react";
import { KBarProvider } from "kbar";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Script from "next/script";
import { VFC } from "react";
import type { ReactElement, ReactNode } from "react";
import useSWR, { SWRConfig } from "swr";

import { theme } from "../theme/theme";

import { Extension } from "api/get-extensions";
import { Integration } from "api/get-integrations";
import { getUser, User } from "api/get-user";
import { Commandbar } from "components/commandbar/commandbar";
import { SearchProvider } from "components/commandbar/search/search-provider";
import { Global } from "components/global";
import { Layout } from "components/layout";
import { TableOfContentsItem } from "components/table-of-contents/table-of-contents";
import { HtEvents } from "components/vendor/ht-events";
import { Pylon } from "components/vendor/pylon";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type PageProps = {
  meta: {
    title?: string;
    description?: string;
    slug?: string;
    type?: string;
    toc?: TableOfContentsItem[];
    lastUpdatedDate?: string;
    icon?: string;
  };
  destinations: Integration[];
  sources: Integration[];
  extensions: Extension[];
  user?: User;
} & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type Props = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

export default function App(props: Props) {
  if (props.Component.getLayout) {
    return props.Component.getLayout(<props.Component {...props.pageProps} />);
  }

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      <Loader {...props} />
    </SWRConfig>
  );
}

const Loader: VFC<Readonly<Partial<Props>>> = ({ Component, pageProps }) => {
  const { data: user, error: userError } = useSWR("user", getUser);

  const loadingUser = !userError && !user;

  return (
    <>
      <ChakraProvider theme={theme}>
        <SearchProvider>
          <KBarProvider
            actions={[]}
            options={{
              animations: {
                enterMs: 250,
                exitMs: 250,
              },
            }}
          >
            <Layout {...pageProps} user={user} loadingUser={loadingUser}>
              <Component {...pageProps} user={user} />
            </Layout>
            <Commandbar />
          </KBarProvider>
        </SearchProvider>
      </ChakraProvider>
      <Pylon user={user} />
      <HtEvents />
      <Script
        src="/third-party-scripts/google-tag-manager.js"
        strategy="lazyOnload"
      />
      <Global />
    </>
  );
};
