import { createIcon } from "@chakra-ui/icons";

export const PlusIcon = createIcon({
  displayName: "PlusIcon",
  viewBox: "0 0 20 20",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10866 1.66699H13.8837C16.717 1.66699 18.3337 3.26699 18.3337 6.10866V13.892C18.3337 16.717 16.7253 18.3337 13.892 18.3337H6.10866C3.26699 18.3337 1.66699 16.717 1.66699 13.892V6.10866C1.66699 3.26699 3.26699 1.66699 6.10866 1.66699ZM10.6837 10.692H13.0503C13.4337 10.6837 13.742 10.3753 13.742 9.99199C13.742 9.60866 13.4337 9.30033 13.0503 9.30033H10.6837V6.95033C10.6837 6.56699 10.3753 6.25866 9.99199 6.25866C9.60866 6.25866 9.30033 6.56699 9.30033 6.95033V9.30033H6.94199C6.75866 9.30033 6.58366 9.37533 6.45033 9.50033C6.32533 9.63366 6.25033 9.80783 6.25033 9.99199C6.25033 10.3753 6.55866 10.6837 6.94199 10.692H9.30033V13.0503C9.30033 13.4337 9.60866 13.742 9.99199 13.742C10.3753 13.742 10.6837 13.4337 10.6837 13.0503V10.692Z"
    />
  ),
});
