import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import * as Components from "./components";
import { typography } from "./typography";

export const theme = extendTheme({
  config: {
    cssVarPrefix: "theme",
  },
  styles: {
    global: {
      html: {
        fontSize: "16px",
      },
      body: {
        fontFamily: 'body',
        color: "text.primary",
        width: "100%",
        height: "100%",
        textRendering: "optimizeLegibility",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
      },
    },
  },
  sizes: {
    max: "max-content",
    min: "min-content",
    full: "100%",
    container: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
  colors,
  components: Components,
  ...typography,
});
