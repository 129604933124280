import { Box, Text } from "@chakra-ui/react";
import { UnorderedList, ListItem } from "ui/list";
import { PylonLink } from "ui/pylon";

export const SourceTestSnippet = ( { source = "your data warehouse" }) => (
<>
<Box>
  <Text textStyle="paragraph">
  When setting up a source for the first time, Hightouch validates the following:
  <UnorderedList>
    <ListItem>Network connectivity</ListItem>
    <ListItem>{source} credentials</ListItem>
    <ListItem>Permission to list schemas and tables</ListItem>
    <ListItem>Permission to write to <code>hightouch_planner</code> schema</ListItem>
    <ListItem>Permission to write to <code>hightouch_audit</code> schema</ListItem>
  </UnorderedList>
  </Text>
  <Text textStyle="paragraph">
   All configurations must pass the first three, while those with the Lightning engine must pass all of them.
  </Text>
  <Text textStyle="paragraph">
    Some sources may initially fail connection tests due to timeouts. Once a connection is established, subsequent API requests should happen more quickly, so it's best to retry tests if they first fail. You can do this by clicking <Text as="strong" fontWeight="semibold">Test again</Text>.
  </Text>
  <Text textStyle="paragraph">
    If you've retried the tests and verified your credentials are correct but the tests are still failing, don't hesitate to{" "}
        <PylonLink 
          pylonText={`Hello, I'm having an issue setting up ${source}.`}
          linkText="reach out to our Customer Success team"
        />.
  </Text>
</Box>
</>
);
