import { createIcon } from "@chakra-ui/icons";

export const IdentityResolutionIcon = createIcon({
  displayName: "IdentityResolutionIcon",
  viewBox: "0 0 24 25",
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M7.405 1.707a.5.5 0 0 0-.852.07l-5 10a.5.5 0 0 0 .598.7l3.896-1.23c.049-.39.135-.767.254-1.13l-3.326 1.05 4.103-8.205 2.547 3.526c.318-.137.651-.248.996-.329L7.405 1.707Zm8.177 5.479c.31.232.598.492.858.778l3.12-.985-2.084 2.565c.16.358.287.733.376 1.124l2.536-3.122L19.6 18.59l-2.489-3.446c-.184.299-.393.58-.626.841l2.639 3.653-9.902 1.65L11.896 18a6.015 6.015 0 0 1-1.178-.136l-3.105 3.822a.5.5 0 0 0 .47.808l12-2a.5.5 0 0 0 .417-.457l1-14a.5.5 0 0 0-.65-.513l-5.267 1.663Z" />
      <path
        fillRule="evenodd"
        d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0 1a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
        clipRule="evenodd"
      />
      <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM3 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm6 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </svg>
  ),
});
