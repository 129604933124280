import { createIcon } from "@chakra-ui/icons";

export const WorkspaceIcon = createIcon({
  displayName: "WorkspaceIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8861 2.02148H16.9254C19.445 2.02148 21.5 4.02148 21.5 6.46148V17.5815C21.5 20.0315 19.445 22.0215 16.9047 22.0215H11.8758C9.35611 22.0215 7.29083 20.0315 7.29083 17.5915V12.7915H13.6932L12.041 14.3915C11.7312 14.6915 11.7312 15.1815 12.041 15.4815C12.1959 15.6315 12.4024 15.7015 12.6089 15.7015C12.8051 15.7015 13.0117 15.6315 13.1666 15.4815L16.1819 12.5715C16.3368 12.4315 16.4194 12.2315 16.4194 12.0215C16.4194 11.8215 16.3368 11.6215 16.1819 11.4815L13.1666 8.57148C12.8568 8.27148 12.3508 8.27148 12.041 8.57148C11.7312 8.87148 11.7312 9.36148 12.041 9.66148L13.6932 11.2515H7.29083V6.47148C7.29083 4.02148 9.35611 2.02148 11.8861 2.02148ZM2.5 12.0214C2.5 11.6014 2.85523 11.2514 3.2815 11.2514H7.29052V12.7914H3.2815C2.85523 12.7914 2.5 12.4514 2.5 12.0214Z"
    />
  ),
});
