import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    scrollMarginTop: "96px",
  },
  sizes: {},
  variants: {},
  defaultProps: {
    textStyle: "h2",
  },
};
