import { Global as EmotionGlobal } from "@emotion/react";

const Fonts = () => (
  <EmotionGlobal
    styles={`
    @font-face {
      font-family: "Nantes";
      font-style: normal;
      font-weight: 400;
      font-display: block;
      src: url(/docs/fonts/nantes-book.woff2) format("woff2");
    }
    @font-face {
      font-family: "Nantes";
      font-style: italic;
      font-weight: 400;
      font-display: block;
      src: url(/docs/fonts/nantes-book-italic.woff2) format("woff2");
    }
    @font-face {
      font-family: "Nantes";
      font-style: normal;
      font-weight: 500;
      font-display: block;
      src: url(/docs/fonts/nantes-medium.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: italic;
      font-weight: 300;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-book-italic.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: normal;
      font-weight: 300;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-book.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: italic;
      font-weight: 400;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-medium-italic.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: normal;
      font-weight: 400;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-medium.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: italic;
      font-weight: 600;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-semibold-italic.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: normal;
      font-weight: 600;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-semibold.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: normal;
      font-weight: 700;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-bold.woff2) format("woff2");
    }
    @font-face {
      font-family: "Sharp Sans Display No 1";
      font-style: italic;
      font-weight: 700;
      font-display: block;
      src: url(/docs/fonts/sharp-sans-disp-no-1-bold-italic.woff2) format("woff2");
    }
    `}
  />
);

const Code = () => (
  <EmotionGlobal styles={`
    .hljs-comment {
      color: var(--theme-colors-text-secondary)
    }
    .hljs-addition {
      color: var(--theme-colors-success-700);
      background: var(--theme-colors-success-100);
    }
    .hljs-deletion {
      color: var(--theme-colors-danger-700);
      background: var(--theme-colors-danger-100);
    }
  `} 
  /> 
);

export const Global = () => (
  <>
    <Fonts />
    <Code />
  </>
)