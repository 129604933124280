import { Text } from "@chakra-ui/react";

import { Link } from "../link";

import { OrderedList, ListItem } from "ui/list";


export const CreateAPIKeySnippet = () => (
  <OrderedList>
    <ListItem>From the{" "} 
      <Link 
        href="https://app.hightouch.com/settings/api-keys"
        textDecoration="underline"
        color="grass"
        >
        <Text as="strong" fontWeight="semibold">API keys</Text> tab
      </Link>{" "}
      on the <Text as="strong" fontWeight="semibold">Settings</Text> page, select <Text as="strong" fontWeight="semibold">Add API key</Text>.
    </ListItem>
    <ListItem>
      Enter a descriptive <Text as="strong" fontWeight="semibold">Name</Text> for your key.
    </ListItem>
    <ListItem>
      Copy your <Text as="strong" fontWeight="semibold">API key</Text> and store it in a safe location. The key will only be displayed once.
    </ListItem>
    <ListItem>
      Click <Text as="strong" fontWeight="semibold">Create API key</Text>.
    </ListItem>
  </OrderedList>
);


