import { Text } from "@chakra-ui/react";
import { VFC, ReactNode } from "react";

import { Heading } from "../heading"
import { PylonLink } from "../pylon";

type Props = {
    featureName?: string;
    featureType?: string;
    children?: ReactNode;
  };

export const WIPStub : VFC<Readonly<Props>> = ({ featureName = "", featureType }) => {
    let linkText = featureName

    if (featureName && featureType == "destination" || featureType == "source") {
        linkText = `connecting to ${featureName}`
    }

    return (
      <>
        <Heading>Under construction</Heading>
        <Text textStyle="paragraph">
          Looks like you're as excited about this feature as we are!
        </Text>
        <Text textStyle="paragraph">
          We're still working on the docs. Please check back in a day or two.
        </Text>
        <Text textStyle="paragraph">In the meantime, don't hesitate to&nbsp;
          <PylonLink pylonText="" linkText="get in touch" />
          &nbsp;if you have any questions about {linkText}.
        </Text>
      </>
    );
  };
