import {
  Flex,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ChakraProps,
  Image as ThemedImage,
} from "@chakra-ui/react";
import { VFC } from "react";

type Props = {
  src: string;
  alt: string;
  title: string;
} & ChakraProps;

export const Image: VFC<Readonly<Props>> = ({ src, alt, title, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        as="figure"
        width="100%"
        bg="base.background"
        borderRadius="4px"
        p={{base: 1, md: 3, xl: 5}}
        cursor="zoom-in"
        pointerEvents={["none", "none", "auto"]}
        onClick={onOpen}
        {...props}
      >
        <Flex width="100%" height="100%" zIndex={0} justify="center">
          <ThemedImage
            alt={alt}
            src={`/docs/images/${src}`}
            objectFit="contain"
            width="auto"
            height="auto"
            maxHeight="76vh"
          />
        </Flex>

        {title && (
          <Box
            as="figcaption"
            textAlign="center"
            mt={4}
            color="gray.600"
            fontWeight="medium"
            fontSize="sm">
            {title}
          </Box>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
        <ModalOverlay
          bg="gray.600"
          backdropFilter="auto"
          backdropBlur="2px"
          zIndex={3}
          width="100%"
          height="100%"
        />

        <ModalContent
          zIndex={3}
          width="max-content"
          height="max-content"
          m="auto"
          containerProps={{ zIndex: 3 }}
        >
          <ModalBody>
            <Flex align="center" justify="center" zIndex={0}>
              <ThemedImage
                src={`/docs/images/${src}`}
                objectFit="contain"
                width="max-content"
                height="auto"
                maxHeight="92vh"
                maxWidth={{ base: "90vw", lg: "75vw"}}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
