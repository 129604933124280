import { createIcon } from "@chakra-ui/icons";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 12C21.25 18.937 18.937 21.25 12 21.25C5.063 21.25 2.75 18.937 2.75 12C2.75 5.063 5.063 2.75 12 2.75C18.937 2.75 21.25 5.063 21.25 12Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12 15.8955V12.0005"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.0045 8.5H11.9955"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
