import { useEffect } from "react";

import { track } from "lib/analytics";

export const openPylon = (message: string): boolean => {
  // If Pylon isn't initialized, or no user is logged in, return false
  if (!window.Pylon || !window.pylon) {
    return false;
  }

  track("Pylon Pop-up Opened", {
    page: window.location.pathname,
  });

  window.Pylon("showNewMessage", message);

  return true;
};

export const Pylon = ({ user }) => {
  const APP_ID = process.env.NEXT_PUBLIC_PYLON_APP_ID;

  useEffect(() => {
    
    // Copied from: https://docs.usepylon.com/chat/setup
    // With a few modifications to make typescript happy
    (function () {
      const e = window;
      if (e.Pylon) return;
      
      const t = document;
      const n = function (...rest) {
        n.e(...rest);
      } as any;
      n.q = [];
      n.e = function (e) {
        n.q.push(e);
      };
      e.Pylon = n;
      const r = function () {
        const e = t.createElement("script");
        e.setAttribute("type", "text/javascript");
        e.setAttribute("async", "true");
        e.setAttribute("src", `https://widget.usepylon.com/widget/${APP_ID}`);
        const n: any = t.getElementsByTagName("script")[0];
        n.parentNode?.insertBefore(e, n);
      };
      if (t.readyState === "complete") {
        r();
      } else if (e.addEventListener) {
        e.addEventListener("load", r, false);
      }
    })();
  }, []);
  
  useEffect(() => {
    if (user && user.pylon_email_hash) {
      window.pylon = {
        chat_settings: {
          app_id: APP_ID,
          name: user.name,
          email: user.email,
          user_id: user.id,
          email_hash: user.pylon_email_hash || "",
        },
      };
    }
  }, [Boolean(user)]);

  return null;
};
