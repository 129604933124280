import { createIcon } from "@chakra-ui/icons";

export const EventsIcon = createIcon({
  displayName: "EventsIcon",
  viewBox: "0 0 24 25",
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="1"
        d="M12.2186 13.818C11.8168 12.8136 12.8136 11.8168 13.818 12.2186L16.3236 11.4298C17.2205 11.1474 17.2673 9.8957 16.394 9.54717L7.16242 5.86299C6.34655 5.53739 5.53739 6.34655 5.86299 7.16242L9.54717 16.394C9.8957 17.2673 11.1474 17.2205 11.4298 16.3236L12.2186 13.818Z"
        fill="black"
      />
      <line
        x1="14.2442"
        y1="14.62"
        x2="17.4967"
        y2="17.8724"
        stroke="black"
        strokeOpacity="1"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
});
