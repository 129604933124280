import { NextSeo } from "next-seo";
import { VFC } from "react";

type Props = {
  title?: string;
  description: string;
  noindex?: boolean;
};

export const Head: VFC<Readonly<Props>> = ({ title, description, noindex=false }) => {
  return (
    <NextSeo
      title={title ? `${title} | Hightouch Docs` : "Hightouch Docs"}
      description={description}
      noindex={noindex}
      openGraph={{
        title,
        description,
        images: [{ url: `/docs/card.png` }],
        site_name: "Hightouch Docs",
      }}
      twitter={{
        handle: "HightouchData",
        cardType: "summary_large_image",
      }}
      additionalLinkTags={[
        {
          rel: "icon",
          href: "/docs/favicon.ico",
        },
      ]}
    />
  );
};
