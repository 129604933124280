import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "4px",
    fontWeight: "semibold",
    transition: "all 150ms ease-in-out",
    svg: {
      transition: "all 150ms ease-in-out",
    },
    _focus: {
      outline: "auto",
    },
    _disabled: {
      pointerEvents: "none",
    },
  },
  sizes: {
    md: {
      px: 4,
      py: 2,
    },
    lg: {
      fontSize: "lg",
      p: 6,
    },
  },
  variants: {
    primary: {
      bg: "lightspeed",
      color: "text.primary",
      _hover: {
        bg: "lightspeedLight"
      },
    },
    secondary: {
      p: 0,
      color: "text.primary",
      _hover: {
        color: "grass",
        svg: {
          fill: "grass"
        }
      },
    },
    tertiary: {
      bg: "transparent",
      border: "1px solid",
      borderColor: "text.primary",
      color: "text.primary",
      _hover: {
        bg: "text.primary",
        color: "white",
        svg: {
          fill: "white"
        }
      }
    },
    dark: {
      bg: "text.primary",
      color: "white",
      _hover: {
        bg: "gray.800"
      },
    },
    grass: {
      bg: "grass",
      color: "white",
      _hover: {
        bg: "grassLight",
      },
    },
    navIcon: {

      color: "text.secondary",
      svg: {
        fill: "text.secondary"
      },
      _hover: {
        bg: "text.primary"
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "secondary",
  },
};
