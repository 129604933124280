import { Box, Text, Link } from "@chakra-ui/react";
import { Info } from "../../alert";

export const SyncLogsSnippet = ( { source = "your data warehouse" }) => (
  <>
  <Box>
    <Text textStyle="paragraph">
    You may also want to consider{" "}
      <Link
        textDecoration="underline"
        color="grass"
        href={"/docs/syncs/warehouse-sync-logs"}
      >
        storing sync logs
      </Link>{" "}in {source}. Like using the Lightning sync engine versus the standard one, this feature lets you use {source} instead of Hightouch infrastructure. Rather than performance gains, it makes your sync log data available for more complex analysis. Refer to the{" "}
      <Link
        textDecoration="underline"
        color="grass"
        href={"/docs/syncs/warehouse-sync-logs"}
      >
        warehouse sync logs docs
      </Link>{" "}to learn more.
    </Text>
    <Info>
    <p>
      You must enable the{" "}
      <Link
        textDecoration="underline"
        color="grass"
        href={"#choose-your-sync-engine"}
      >
        Lightning sync engine
      </Link>{" "}
      to store sync logs in your warehouse.
    </p>
  </Info>
    
  </Box>
</>
);
