import { Button, Flex, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { VFC, useState } from "react";

import { track } from "lib/analytics";
import { SmileIcon, FrownIcon } from "ui/icons";
import { PylonButton } from "ui/pylon";

export const Feedback: VFC = () => {
  const router = useRouter();
  const [isHappy, setIsHappy] = useState<boolean>(false);

  return (
    <Flex
      direction="column"
      pt={4}
      mt={2}
      borderTop="1px solid"
      borderColor="gray.100"
    >
      <Text fontWeight="semibold" fontSize="sm" mb={1}>
        Was this page helpful?
      </Text>
      <Flex align="center" gap={4}>
        {isHappy ? (
          <Flex
            align="center"
            fontWeight="medium"
            fontSize="sm"
            height="32px"
            px={2}
          >
            <SmileIcon />
            <Text ml={2}>Thank you!</Text>
          </Flex>
        ) : (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                track("Page Helpful Icon Clicked", {
                  page: router.pathname,
                });
                setIsHappy(true);
              }}
            >
              <SmileIcon />
              <Text ml={2}>Yes</Text>
            </Button>
            <PylonButton
              variant="secondary"
              message="Hello, the docs didn't answer my question. I need help with..."
            >
              <FrownIcon />
              <Text ml={2}>No</Text>
            </PylonButton>
          </>
        )}
      </Flex>
    </Flex >
  );
};
