import { Text, Link } from "@chakra-ui/react";

export const SyncAlerts = () => (
    <Text textStyle="paragraph">
      Hightouch can alert you of sync issues via Slack, PagerDuty, SMS, or email. For details, please visit our{" "}
      <Link
        textDecoration="underline"
        color="grass"
        href={"/docs/syncs/alerting"}
          >
        article on alerting
      </Link>.
    </Text>
);