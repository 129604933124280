import { Box, ChakraProps } from "@chakra-ui/react";
import { FC } from "react";

export const OrderedList: FC<Readonly<ChakraProps>> = ({
  children,
  ...props
}) => (
  <Box as="ol" py={0} px={5} my={6} {...props}>
    {children}
  </Box>
);

export const UnorderedList: FC<Readonly<ChakraProps>> = ({
  children,
  ...props
}) => (
  <Box as="ul" py={0} px={5} my={6} listStyleType="disc" {...props}>
    {children}
  </Box>
);

export const ListItem: FC<Readonly<ChakraProps>> = ({ children, ...props }) => (
  <Box as="li" my={2} {...props}>
    {children}
  </Box>
);
