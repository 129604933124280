import { Table, TD, TH, TR } from "ui/table";

export const EngineComparisonTable = ( { source = "your data warehouse" }) => (
  <>
      <Table>
      <TR>
        <TH>Criteria</TH>
        <TH>Basic sync engine</TH>
        <TH>Lightning sync engine</TH>
      </TR>
      <TR>
        <TD>Performance</TD>
        <TD>Slower</TD>
        <TD>Quicker</TD>
      </TR>
      <TR>
        <TD>Ideal for large data models (over 100 thousand rows)</TD>
        <TD>No</TD>
        <TD>Yes</TD>
      </TR>
      <TR>
        <TD>Reliability</TD>
        <TD>Normal</TD>
        <TD>High</TD>
      </TR>
      <TR>
        <TD>Resilience to sync interruptions</TD>
        <TD>Normal</TD>
        <TD>High</TD>
      </TR>
      <TR>
        <TD>Extra features</TD>
        <TD>None</TD>
        <TD>Warehouse Sync Logs, Match Booster, Identity Resolution</TD>
      </TR>
      <TR>
        <TD>Ease of setup</TD>
        <TD>Simpler</TD>
        <TD>More involved</TD>
      </TR>
      <TR>
        <TD>Location of change data capture</TD>
        <TD>Hightouch infrastructure</TD>
        <TD>{source} schemas managed by Hightouch</TD>
      </TR>
      <TR>
        <TD>Required permissions in {source}</TD>
        <TD>Read-only</TD>
        <TD>Read and write</TD>
      </TR>
      <TR>
        <TD>Ability to switch</TD>
        <TD>You can move to the Lightning engine at any time</TD>
        <TD>You can't move to the Basic engine once Lightning is configured</TD>
      </TR>
    </Table>
  </>
);
