import { Box, Flex, Text } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { VFC, forwardRef, Ref, MouseEventHandler } from "react";
import { connectStateResults } from "react-instantsearch-dom";

import { Results } from "../results";

import { Highlight } from "./highlight";

import { Link } from "ui";
import {
  APIIcon,
  AudienceIcon,
  DestinationIcon,
  ExtensionIcon,
  HomeIcon,
  ModelIcon,
  SecurityIcon,
  SourceIcon,
  SyncIcon,
  WorkspaceIcon,
} from "ui/icons";

export type Hit = {
  objectID: string;
  title: string;
  value: string;
  path: string;
  rank: number;
  type: string;
  heirarchy: string[];
  description: string;
};

export const Hits = connectStateResults(({ searchResults }) => {
  if (searchResults?.query?.length < 1 || !searchResults?.hits) {
    return null;
  }

  if (searchResults?.hits?.length === 0) {
    return (
      <Text
        textAlign="center"
        pb={8}
        pt={4}
        color="text.primary"
        fontSize="sm"
        fontWeight="semibold"
      >
        No results match your search
      </Text>
    );
  }

  // sort to keep subsections grouped together
  const sortedResults = sortBy(searchResults.hits, ["rank", "heirarchy"]);

  return (
    <Results
      items={sortedResults}
      onRender={({ item, ...props }) => <Hit hit={item} {...props} />}
    />
  );
});

export const Hit: VFC<
  Readonly<{
    hit: any;
    active: boolean;
    onClick: MouseEventHandler;
    onMouseMove: MouseEventHandler;
  }>
> = forwardRef(
  ({ hit, active, onClick, onMouseMove }, ref: Ref<HTMLDivElement>) => {
    const { heirarchy, path, title, type } = hit;

    return (
      <Link
        width="100%"
        height="100%"
        borderBottom="1px solid"
        borderColor={active ? "avocado" : "gray.100"}
        ref={ref}
        display="flex"
        alignItems="flex-start"
        href={path}
        p={4}
        bg={active ? "avocado" : "white"}
        onClick={onClick}
        onMouseMove={onMouseMove}
        lineHeight="1.625rem"
        fontWeight="semibold"
      >
        <Flex mt={1} mr={2} justify="center">
          {path.includes("/destinations") && (
            <DestinationIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/sources") && (
            <SourceIcon
              fill="text.primary"
              stroke="white"
              width="1.25rem"
              height="100%"
            />
          )}
          {path.includes("/syncs") && (
            <SyncIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/customer-studio") && (
            <AudienceIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/models") && (
            <ModelIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/security") && (
            <SecurityIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/workspace-management") && (
            <WorkspaceIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/getting-started") && (
            <HomeIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/extensions") && (
            <ExtensionIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/pricing") && (
            <ModelIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
          {path.includes("/developer-tools") && (
            <APIIcon fill="text.primary" width="1.25rem" height="100%" />
          )}
        </Flex>

        <Text as="span" lineHeight="1.625rem">
          {heirarchy.map((h) => (
            <>
              <Text as="span" >
                {h}
              </Text>
              <Text as="span" fontWeight="bold" fontSize="lg">
                &nbsp;
                {"›"}
                &nbsp;
              </Text>
            </>
          ))}
          {type !== "description" ? (
            <Highlight hit={hit} attribute="value" />
          ) : (
            title
          )}
        </Text>
      </Link >
    );
  }
);

Hit.displayName = "Hit";
