import { createIcon } from "@chakra-ui/icons";

export const SourceIcon = createIcon({
  displayName: "SourceIcon",
  viewBox: "0 0 24 25",
  path: [
    <path
      strokeOpacity={0}
      d="M20.4468 4.32474C20.4468 6.61437 20.4468 8.54361 20.4035 10.169C20.4035 19.5705 21.8338 22.1115 11.7792 22.1115C1.72467 22.1115 3.11174 20.0787 3.11174 9.40666C3.11153 8.29625 3.11165 6.99382 3.11165 4.32474C3.11165 2.5004 6.99225 1.02148 11.7792 1.02148C16.5662 1.02148 20.4468 2.5004 20.4468 4.32474Z"
    />,
    <path
      d="M17.3613 5.80822C17.3613 5.80822 17.1404 7.64941 12.0587 7.64941C6.97702 7.64941 6.31419 5.80822 6.31419 5.80822"
      strokeWidth="1.5"
      fillOpacity={0}
      strokeLinecap="round"
    />,
    <path
      d="M17.3613 11.3326C17.3613 11.3326 17.1404 13.1738 12.0587 13.1738C6.97702 13.1738 6.31419 11.3326 6.31419 11.3326"
      strokeWidth="1.5"
      fillOpacity={0}
      strokeLinecap="round"
    />,
    <path
      d="M17.3613 16.8561C17.3613 16.8561 17.1404 18.6973 12.0587 18.6973C6.97702 18.6973 6.31419 16.8561 6.31419 16.8561"
      strokeWidth="1.5"
      fillOpacity={0}
      strokeLinecap="round"
    />,
  ],
});
