import { Info } from "../alert";
import { Link } from "../link";

export const BtOnly = ({ featureName = "This feature", plural=false, extraText="" }) => (
  <Info>
    {featureName} {plural ? "are" : "is" } only available on{" "}
    <Link
        textDecoration="underline"
        color="grass"
        href={"https://hightouch.com/pricing"}
    >
      Business tier plans
    </Link>
    {extraText ? extraText : "."}
  </Info>
);
