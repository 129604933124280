import { Text } from "@chakra-ui/react";

import { PylonLink } from "ui/pylon";

export const CommonErrorsPreface = () => (
  <Text textStyle="paragraph">
  If you encounter an error or question not listed below and need assistance, don't hesitate to{" "}
    <PylonLink pylonText="" linkText="reach out" />.   
  We're here to help.
  </Text>
);
