import { Box, Text } from "@chakra-ui/react";
import { MDXProvider } from "@mdx-js/react";
import { FC, ReactNode } from "react";

import { DemoEmbed } from "./demo-embed";

import {
  Heading,
  Link,
  PylonLink,
  BtOnly,
  PremiumDestination,
  APIKeyWarning,
  EventSyncWarning,
  WIPStub,
  SetSyncSchedule,
  SyncEngineSnippet,
  SourceTestSnippet,
  SyncLogsSnippet,
  ConnectionTypeSnippet,
  EngineComparisonTable,
  NoCommonErrors,
  MatchRatesExplanation,
  CommonErrorsPreface,
  SyncAlerts,
  LiveDebugger,
  MatchBoosterDestination,
  TimestampTemplateMapping,
  CreateAPIKeySnippet,
} from "ui";
import { DownloadLink } from "ui/download-link";
import { Image } from "ui/image";
import { OrderedList, UnorderedList, ListItem } from "ui/list";
import { Table, TD, TH, TR } from "ui/table";
import { Video } from "ui/video";

const components = {
  h1: (props) => <Heading as="h1" {...props} />,
  h2: (props) => <Heading as="h2" {...props} />,
  h3: (props) => <Heading as="h3" {...props} />,
  h4: (props) => <Heading as="h4" {...props} />,
  p: (props) => <Text textStyle="paragraph" {...props} />,
  span: (props) => <Text as="span" textStyle="paragraph" {...props} />,
  strong: (props) => <Text as="strong" fontWeight="semibold" {...props} />,
  img: (props) => {
    return <Image {...props} my={6} />;
  },
  li: ListItem,
  ol: OrderedList,
  ul: UnorderedList,
  table: Table,
  td: TD,
  th: TH,
  tr: TR,
  a: (props) => <Link {...props} textDecoration="underline" color="grass" />,
  Heading,
  PylonLink,
  BtOnly,
  PremiumDestination,
  EventSyncWarning,
  WIPStub,
  CreateAPIKeySnippet,
  APIKeyWarning,
  SetSyncSchedule,
  SyncEngineSnippet,
  SourceTestSnippet,
  SyncLogsSnippet,
  ConnectionTypeSnippet,
  EngineComparisonTable,
  NoCommonErrors,
  MatchRatesExplanation,
  CommonErrorsPreface,
  SyncAlerts,
  LiveDebugger,
  MatchBoosterDestination,
  TimestampTemplateMapping,
  pre: (props) => <Box as="pre" {...props} my={6} />,
  code: (props) => {
    if (props.className?.includes("hljs")) {
      // multi-line code
      return (
        <Box
          as="code"
          bg="base.lightBackground"
          border="1px solid"
          borderColor="base.border"
          borderRadius="md"
          color="text.primary"
          fontFamily="mono"
          fontSize="sm"
          paddingX={1}
          whiteSpace="pre"
          {...props}
        />
      );
    }
    // inline code
    return (
      <Box
        as="code"
        display="inline"
        bg="base.background"
        borderRadius="sm"
        color="text.primary"
        fontFamily="mono"
        fontSize="sm"
        paddingX={1}
        paddingY={0.5}
        whiteSpace="pre-wrap"
        {...props}
      />
    );
  },
  CodeNoWrap: (props) => {
    return (
      <Box
        as="code"
        bg="base.lightBackground"
        border="1px solid"
        borderColor="base.border"
        borderRadius="md"
        color="text.primary"
        fontFamily="mono"
        fontSize="sm"
        paddingX={1}
        whiteSpace="pre-wrap"
        {...props}
      />
    );
  },
  Video: ({ poster, source, duration, title, publishedAt }) => {
    return (
      <Video
        poster={poster}
        source={source}
        duration={duration}
        title={title}
        description={
          title +
          ". Get started quickly with this video tutorial from Hightouch Docs."
        }
        uploadDate={publishedAt ?? new Date().toISOString()}
        mb={12}
        transition="150ms all"
      />
    );
  },
  DemoEmbed: ({ title, description, demoId, demoName }) => {
    return (
      <DemoEmbed
        title={title}
        description={description}
        demoId={demoId}
        demoName={demoName}
      />
    );
  },
  DownloadLink: ({ href, fileName, ...props }) => {
    return <DownloadLink href={href} fileName={fileName} {...props} />;
  },
};

export const MDX: FC<Readonly<{ children: ReactNode }>> = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
);
