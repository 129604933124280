import { createIcon } from "@chakra-ui/icons";

export const DestinationIcon = createIcon({
  displayName: "DestinationIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.54 2.02148H7.92C9.33 2.02148 10.46 3.17148 10.46 4.58248V7.99148C10.46 9.41148 9.33 10.5515 7.92 10.5515H4.54C3.14 10.5515 2 9.41148 2 7.99148V4.58248C2 3.17148 3.14 2.02148 4.54 2.02148ZM4.54 13.4912H7.92C9.33 13.4912 10.46 14.6322 10.46 16.0522V19.4612C10.46 20.8712 9.33 22.0212 7.92 22.0212H4.54C3.14 22.0212 2 20.8712 2 19.4612V16.0522C2 14.6322 3.14 13.4912 4.54 13.4912ZM19.4601 2.02148H16.0801C14.6701 2.02148 13.5401 3.17148 13.5401 4.58248V7.99148C13.5401 9.41148 14.6701 10.5515 16.0801 10.5515H19.4601C20.8601 10.5515 22.0001 9.41148 22.0001 7.99148V4.58248C22.0001 3.17148 20.8601 2.02148 19.4601 2.02148ZM16.0801 13.4912H19.4601C20.8601 13.4912 22.0001 14.6322 22.0001 16.0522V19.4612C22.0001 20.8712 20.8601 22.0212 19.4601 22.0212H16.0801C14.6701 22.0212 13.5401 20.8712 13.5401 19.4612V16.0522C13.5401 14.6322 14.6701 13.4912 16.0801 13.4912Z"
    />
  ),
});
