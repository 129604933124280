import { Flex, Box } from "@chakra-ui/react";
import { FC } from "react";

import { InfoIcon, StarIcon, WarningIcon } from "ui/icons";

type MessageProps = {
  variant?: "info" | "tip" | "warning";
};

const variants = {
  info: {
    borderColor: "text.secondary",
    bg: "base.background",
  },
  tip: {
    borderColor: "grass",
    bg: "avocado",
  },
  warning: {
    borderColor: "yellow.300",
    bg: "yellow.50",
  },
};

const icons = {
  info: (
    <InfoIcon
      mr={3}
      boxSize={6}
      flexShrink={0}
      fill="transparent"
      stroke="text.primary"
    />
  ),
  warning: (
    <WarningIcon
      mr={3}
      boxSize={6}
      flexShrink={0}
      fill="transparent"
      stroke="yellow.600"
    />
  ),
  tip: (
    <StarIcon
      mr={3}
      boxSize={6}
      flexShrink={0}
      fill="transparent"
      stroke="grass"
    />
  ),
};

export const Alert: FC<Readonly<MessageProps>> = ({
  children,
  variant = "info",
}) => {
  const icon = icons[variant];

  return (
    <Flex
      border="1px solid"
      borderRadius="4px"
      padding={4}
      my={12}
      fontWeight="medium"
      {...variants[variant]}
    >
      {icon}
      <Box sx={{ p: { margin: 0 }, }}>
        {children}
      </Box>
    </Flex>
  );
};

export const Tip = (props) => <Alert variant="tip" {...props} />;

export const Info = (props) => <Alert variant="info" {...props} />;

export const Warning = (props) => <Alert variant="warning" {...props} />;
