import { Text, Flex } from "@chakra-ui/react";
import { VFC } from "react";

type Props = {
  date: string;
};

export const LastUpdated: VFC<Readonly<Props>> = ({ date }) => {

  const rawDate = new Date(date);
  // Correct date—for some reason it is always one day earlier than the string in the front matter
  const utcDate = rawDate.getUTCDate();
  const utcMonth = rawDate.getUTCMonth();
  const utcYear = rawDate.getUTCFullYear();

  const lastUpdateDate = new Date (utcYear, utcMonth, utcDate);
  const lastUpdatedText = lastUpdateDate.toLocaleString('en-us', { year:"numeric", month:"short", day:"numeric"});
  // const currentDate = new Date();
  // const thirtyDaysAgo = new Date(new Date().setDate(currentDate.getDate() - 30));
  // let lastUpdatedText = "";

  // // If the lastupdateTimeStamp is older than 30 days ago, set an absolute time ()
  // if (lastUpdateDate < thirtyDaysAgo){
  //   lastUpdatedText = lastUpdateDate.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"});
  // }
  //   // Otherwise, set it to a relative time
  // else {
  //   const daysAgo = Math.ceil((currentDate.valueOf() - lastUpdateDate.valueOf())/86400000);
  //   const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
  //   lastUpdatedText = rtf1.format(-daysAgo, 'day');
  // }

  return (
    <Flex
      direction="column"
      pb={2}

    >
      {date && (
        <Text 
        fontSize="sm"
        color="text.secondary"
        fontWeight="medium"
        fontStyle="italic"
        mb={1}
        >
          Last updated: { lastUpdatedText }
        </Text>
      )}
    </Flex>
  );
};
