import { Box, Text } from "@chakra-ui/react";
import { Link } from "../link";
import { Image } from "ui/image"; 


export const SetSyncSchedule = ({ extension = "" }) => (
<>
<Box>
  <Text textStyle="paragraph">
    For the {extension} extension to trigger syncs via the Hightouch API, you must set the syncs'{" "}
    <Link
      href="/syncs/schedule-sync-ui"
      textDecoration="underline"
      color="grass"
      >
      schedules
    </Link>{" "}
    to <Text as="strong" fontWeight="semibold">Manual</Text>.
  </Text>
  <Text textStyle="paragraph">
    You can do this by going to the{" "}
  <Link
    href="https://app.hightouch.com/syncs"
    textDecoration="underline"
    color="grass"
    >
    <Text as="strong" fontWeight="semibold">Syncs </Text>
    overview page
  </Link>
  , selecting the particular sync you want to trigger with {extension}, and opening the <Text as="strong" fontWeight="semibold">Schedule</Text> tab. Here, make sure the <Text as="strong" fontWeight="semibold">Schedule type</Text> is set to <Text as="strong" fontWeight="semibold">Manual</Text>. You should do this for each sync you want to trigger with {extension}.
  </Text>
  <Image
      alt="Manual sync Hightouch UI"
      src="/syncs/manual-schedule.png"
      title=""
      objectFit="contain"
      width="auto"
      height="auto"
      my={6}
      />
  <Text textStyle="paragraph">
    You can also find the sync ID on this page, which you need for scheduling syncs in the next step.
  </Text>
  <Image
      alt="A sync ID in the Hightouch UI"
      src="/syncs/sync-id.png"
      title=""
      objectFit="contain"
      width="auto"
      height="auto"
      my={6}
      />
</Box>
</>
);


