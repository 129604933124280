import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Kbd: ComponentStyleConfig = {
  baseStyle: {
    display: "flex",
    flexShrink: 0,
    height: "20px",
    width: "20px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    fontWeight: "medium",
    fontSize: "xs",
    bg: "gray.100",
    color: "gray.600",
    whiteSpace: "nowrap",
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
