import { createIcon } from "@chakra-ui/icons";

export const APIIcon = createIcon({
  displayName: "APIIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92574 16.4115H14.3119C14.7178 16.4115 15.0545 16.0715 15.0545 15.6615C15.0545 15.2515 14.7178 14.9215 14.3119 14.9215H8.92574C8.5198 14.9215 8.18317 15.2515 8.18317 15.6615C8.18317 16.0715 8.5198 16.4115 8.92574 16.4115ZM12.2723 9.92148H8.92574C8.5198 9.92148 8.18317 10.2615 8.18317 10.6715C8.18317 11.0815 8.5198 11.4115 8.92574 11.4115H12.2723C12.6782 11.4115 13.0149 11.0815 13.0149 10.6715C13.0149 10.2615 12.6782 9.92148 12.2723 9.92148ZM19.3381 9.0471C19.5708 9.04441 19.8242 9.04148 20.0545 9.04148C20.302 9.04148 20.5 9.24148 20.5 9.49148V17.5315C20.5 20.0115 18.5099 22.0215 16.0545 22.0215H8.17327C5.59901 22.0215 3.5 19.9115 3.5 17.3115V6.53148C3.5 4.05148 5.5 2.02148 7.96535 2.02148H13.2525C13.5099 2.02148 13.7079 2.23148 13.7079 2.48148V5.70148C13.7079 7.53148 15.203 9.03148 17.0149 9.04148C17.4381 9.04148 17.8112 9.04465 18.1377 9.04742C18.3917 9.04957 18.6175 9.05148 18.8168 9.05148C18.9578 9.05148 19.1405 9.04938 19.3381 9.0471ZM19.6111 7.58748C18.7972 7.59048 17.8378 7.58748 17.1477 7.58048C16.0527 7.58048 15.1507 6.66948 15.1507 5.56348V2.92748C15.1507 2.49648 15.6685 2.28248 15.9646 2.59348C16.5004 3.15625 17.2368 3.92983 17.9699 4.69986C18.7009 5.46781 19.4286 6.23223 19.9507 6.78048C20.2398 7.08348 20.0279 7.58648 19.6111 7.58748Z"
    />
  ),
});
