import { createIcon } from "@chakra-ui/icons";

export const ModelIcon = createIcon({
  displayName: "ModelIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33 2.02148H16.669C20.07 2.02148 21.99 3.95048 22 7.35148V16.6915C22 20.0915 20.07 22.0215 16.669 22.0215H7.33C3.929 22.0215 2 20.0915 2 16.6915V7.35148C2 3.95048 3.929 2.02148 7.33 2.02148ZM12.049 17.8815C12.48 17.8815 12.839 17.5615 12.879 17.1315V6.94148C12.919 6.63148 12.77 6.32048 12.5 6.15148C12.219 5.98148 11.879 5.98148 11.61 6.15148C11.339 6.32048 11.19 6.63148 11.219 6.94148V17.1315C11.27 17.5615 11.629 17.8815 12.049 17.8815ZM16.65 17.8815C17.07 17.8815 17.429 17.5615 17.48 17.1315V13.8515C17.509 13.5305 17.36 13.2315 17.089 13.0615C16.82 12.8915 16.48 12.8915 16.2 13.0615C15.929 13.2315 15.78 13.5305 15.82 13.8515V17.1315C15.86 17.5615 16.219 17.8815 16.65 17.8815ZM8.219 17.1315C8.179 17.5615 7.82 17.8815 7.389 17.8815C6.959 17.8815 6.599 17.5615 6.56 17.1315V10.2215C6.53 9.91048 6.679 9.60148 6.95 9.43148C7.219 9.26148 7.56 9.26148 7.83 9.43148C8.099 9.60148 8.25 9.91048 8.219 10.2215V17.1315Z"
    />
  ),
});
