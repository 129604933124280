import { Button, ButtonProps, Flex, FlexProps, LinkProps } from "@chakra-ui/react";
import { FC } from "react";

import { track } from "lib/analytics";
import { Link } from "ui";

interface CTALinksProps extends FlexProps {
  primary: "signup" | "demo";
  primaryVariant?: ButtonProps["variant"];
  primaryText?: string;
  includeChangelog?: boolean;
  hideSecondary?: boolean;
}


const ChangelogLink: FC<Readonly<LinkProps>> = ({ ...props }) =>  (
  <Link href="https://changelog.hightouch.io/" fontSize="sm" { ...props }>
    Changelog
  </Link>
);

export const CTALinks: FC<Readonly<CTALinksProps>> = ({
  primary,
  primaryText,
  primaryVariant = "dark",
  includeChangelog,
  hideSecondary,
  ...props
}) => {
  const demoClicked = () => {
    track("Demo Clicked", {
      page: window.location.href,
    });
  };
  
  const signUpClicked = () => {
    track("Sign Up Clicked", {
      page: window.location.href,
    });
  };
  
  const changelogClicked = () => {
    track("Changelog Clicked", {
      page: window.location.href,
    });
  };

  if (primary === "signup") {
    return (
      <Flex align="center" gap={3} {...props}>
        {includeChangelog && <ChangelogLink onClick={changelogClicked} />}

        <Button
          as="a"
          href="https://hightouch.com/demo"
          variant={primaryVariant}
          display={{ base: "flex", md: "none" }}
          onClick={demoClicked}
        >
          Book a demo
        </Button>

        <Button
          as="a"
          href="https://app.hightouch.com/signup"
          variant={primaryVariant}
          display={{ base: "none", md: "flex" }}
          onClick={signUpClicked}
        >
          {primaryText || "Sign up"}
        </Button>

        {!hideSecondary && (
          <Button
            as="a"
            href="https://hightouch.com/demo"
            variant="tertiary"
            display={{ base: "none", md: "flex" }}
            onClick={demoClicked}
          >
            Book a demo
          </Button>
        )}
      </Flex>
    );
  }

  return (
    <Flex align="center" gap={3} {...props}>
      {includeChangelog && <ChangelogLink onClick={changelogClicked} />}

      <Button
        as="a"
        href="https://hightouch.com/demo"
        variant={primaryVariant}
        onClick={demoClicked}
      >
        {primaryText || "Book a demo"}
      </Button>

      {!hideSecondary && (
        <Button
          as="a"
          href="https://app.hightouch.com/signup"
          variant="tertiary"
          display={{ base: "none", md: "flex" }}
          onClick={signUpClicked}
        >
          Sign up
        </Button>
      )}
    </Flex>
  );
};
