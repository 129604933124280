import { createIcon } from "@chakra-ui/icons";

export const ExtensionIcon = createIcon({
  displayName: "ExtensionIcon",
  viewBox: "0 0 24 25",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 40 44" fillRule="evenodd">
      <path d="M0 22.0107C0.100049 26.689 0.380186 34.6909 0.420205 35.5734C0.562275 37.4603 1.28463 39.3672 2.40918 40.7119C3.97394 42.5988 5.90088 43.4412 8.58819 43.4412C12.302 43.4612 16.396 43.4612 20.3719 43.4612C24.3639 43.4612 28.2378 43.4612 31.5094 43.4412C34.1587 43.4412 36.1457 42.5768 37.6904 40.7119C38.815 39.3672 39.5373 37.4403 39.6394 35.5734C39.6794 34.831 39.8795 26.1448 39.9995 22.0107H0Z" />
      <path d="M18.0088 27.6241V30.4042C18.0088 31.2937 18.9052 32.0156 20.0098 32.0156C21.1143 32.0156 22.0107 31.2937 22.0107 30.4042V27.6241C22.0107 26.7346 21.1143 26.0127 20.0098 26.0127C18.9052 26.0127 18.0088 26.7346 18.0088 27.6241" fill="white" />
      <path d="M14.7752 28.2158C15.5316 28.3158 16.2299 27.8516 16.43 27.1252C16.8742 25.5005 18.3049 24.4099 19.9898 24.4099C21.6526 24.4099 23.0993 25.5245 23.5035 27.1172C23.6736 27.7896 24.2799 28.2478 24.9582 28.2478C25.0262 28.2478 25.0943 28.2438 25.1643 28.2338C30.3028 27.5234 35.2012 25.7046 39.3272 22.9732C39.7454 22.6951 39.9995 22.2249 39.9995 21.7206V14.7852C39.9995 10.5832 36.5819 7.1655 32.3818 7.1655H28.4259C28.0497 4.26008 25.5865 2.00098 22.583 2.00098H17.4165C14.411 2.00098 11.9498 4.26008 11.5737 7.1655H7.63773C3.42567 7.1655 0 10.5832 0 14.7852V21.7206C0 22.2249 0.252123 22.6971 0.674329 22.9732C4.79234 25.6925 9.67072 27.5034 14.7752 28.2158" fill="white" />
      <path d="M14.7752 26.2148C15.5316 26.3148 16.2299 25.8506 16.43 25.1243C16.8742 23.4995 18.3049 22.4089 19.9898 22.4089C21.6526 22.4089 23.0993 23.5235 23.5035 25.1163C23.6736 25.7886 24.2799 26.2468 24.9582 26.2468C25.0262 26.2468 25.0943 26.2428 25.1643 26.2328C30.3028 25.5225 35.2012 23.7036 39.3272 20.9722C39.7454 20.6941 39.9995 20.2239 39.9995 19.7196V12.7842C39.9995 8.58219 36.5819 5.16452 32.3818 5.16452H28.4259C28.0497 2.2591 25.5865 0 22.583 0H17.4165C14.411 0 11.9498 2.2591 11.5737 5.16452H7.63773C3.42567 5.16452 0 8.58219 0 12.7842V19.7196C0 20.2239 0.252123 20.6961 0.674329 20.9722C4.79234 23.6916 9.67072 25.5025 14.7752 26.2148" />
    </svg>
  ),
});
