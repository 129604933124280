import { createIcon } from "@chakra-ui/icons";

export const SecurityIcon = createIcon({
  displayName: "SecurityIcon",
  viewBox: "0 0 24 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7281 21.9351C11.8388 21.993 11.9627 22.0224 12.0865 22.0214C12.2103 22.0205 12.3331 21.9901 12.4449 21.9312L16.0128 20.0239C17.0245 19.4846 17.8168 18.8815 18.435 18.1794C19.779 16.6497 20.5129 14.6973 20.4998 12.6841L20.4575 6.04347C20.4535 5.2786 19.9511 4.59609 19.2082 4.348L12.5707 2.12105C12.1711 1.98573 11.7331 1.98867 11.3405 2.12791L4.72824 4.43429C3.9893 4.69219 3.496 5.3796 3.50002 6.14545L3.54231 12.7812C3.5554 14.7973 4.31448 16.7409 5.68062 18.2549C6.3048 18.9472 7.10415 19.5415 8.12699 20.072L11.7281 21.9351ZM10.7836 14.1304C10.9326 14.2736 11.1259 14.3442 11.3192 14.3422C11.5125 14.3412 11.7047 14.2687 11.8517 14.1236L15.7508 10.2796C16.0438 9.9903 16.0408 9.5255 15.7448 9.24014C15.4478 8.95478 14.9696 8.95675 14.6766 9.24602L11.3081 12.5664L9.92885 11.2406C9.63186 10.9552 9.15467 10.9582 8.8607 11.2474C8.56774 11.5367 8.57076 12.0015 8.86775 12.2869L10.7836 14.1304Z"
    />
  ),
});
