import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "semibold",
    height: "24px",
    px: 2,
    textTransform: "uppercase",
    fontSize: "xs",
  },
  sizes: {},
  variants: {
    primary: {
      bg: "avocado",
      color: "forest",
    },
    cyan: {
      bg: "cyan.50",
      color: "cyan.700",
    },
  },
  defaultProps: {
    variant: "primary",
  },
};
