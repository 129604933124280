import { Warning } from "../alert";
import { Link } from "../link";

export const APIKeyWarning = () => (
  <Warning>
    Your API key provides read/write access to sensitive Hightouch resources and should be kept secure. If you believe your API key may have been compromised,{" "}
    <Link
        textDecoration="underline"
        color="grass"
        href={"/developer-tools/api-guide#revoke-an-api-key"}
    >
      revoke it
    </Link>{" "}
    immediately.
  </Warning>
);


