import { Flex, Kbd, Text } from "@chakra-ui/react";
import { useKBar } from "kbar";
import { VFC } from "react";

import { SearchIcon } from "ui/icons";

export const SearchBox: VFC = () => {
  const { query } = useKBar();

  return (
    <Flex
      px={2}
      height="32px"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      justify="space-between"
      align="center"
      width="100%"
      maxWidth="300px"
      cursor="pointer"
      transition="150ms border-color"
      _hover={{
        borderColor: "grass"
      }}
      onClick={() => {
        query.toggle();
      }}
    >
      <Flex align="center">
        <SearchIcon fill="gray.400" />
        <Text fontSize="sm" color="gray.400" ml={2} fontWeight="medium">
          Search documentation...
        </Text>
      </Flex>

      <Flex align="center" display={["none", "none", "flex"]}>
        <Kbd mr={1} fontSize="md">
          ⌘
        </Kbd>
        <Kbd>K</Kbd>
      </Flex>
    </Flex>
  );
};
