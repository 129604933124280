import algoliasearch from "algoliasearch/lite";
import { FC } from "react";
import { InstantSearch, Configure } from "react-instantsearch-dom";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
);

export const SearchProvider: FC = ({ children }) => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX}
    >
      <Configure
        distinct
        hitsPerPage={25}
        page={0}
        exactOnSingleWordQuery="word"
      />
      {children}
    </InstantSearch>
  );
};
