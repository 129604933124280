import { Text, Flex } from "@chakra-ui/react";
import { VFC } from "react";

import { Link } from "ui";

export type TableOfContentsItem = {
  value: string;
  depth: number;
  id: string;
};

type Props = {
  toc: TableOfContentsItem[];
};

export const TableOfContents: VFC<Readonly<Props>> = ({ toc }) => {
  if (!toc?.length) {
    return null;
  }

  return (
    <Flex 
      direction="column"
      overflow="hidden"
      borderTop="1px solid"
      borderColor="gray.100"
      >
      <Text
        mt={2}
        mb={2}
        fontWeight="semibold"

      >
        On this page
      </Text>
      <Flex direction="column" overflowY="auto" maxHeight="80vh">
        <Flex direction="column">
          {toc.map(({ value, id, depth }) => (
            <Link
              key={id}
              href={`#${id}`}
              py={1}
              pl={depth * 4}
              fontSize="sm"
              color="text.secondary"
              fontWeight="medium"
              transition="150ms color ease-in-out"
              _hover={{ color: "text.primary" }}
            >
              {value}
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
