import { IconProps } from "@chakra-ui/react";

import {
  APIIcon,
  AudienceIcon,
  DestinationIcon,
  ExtensionIcon,
  HomeIcon,
  IconComponent,
  ModelIcon,
  SecurityIcon,
  SourceIcon,
  SyncIcon,
  WorkspaceIcon,
  EventsIcon,
} from "ui/icons";
import { IdentityResolutionIcon } from "ui/icons/idr";
import { SparkleIcon } from "ui/icons/sparkle";

export type NavItem = {
  label: string;
  icon?: IconComponent;
  iconProps?: IconProps;
  path?: string;
  href?: string;
  nav?: NavItem[];
  private?: boolean;
};

export const config: NavItem[] = [
  {
    label: "Getting started",
    icon: HomeIcon,
    path: "/getting-started",
    nav: [
      {
        label: "Welcome",
        path: "/welcome",
        href: "/",
      },
      {
        label: "Core concepts",
        path: "/concepts",
      },
      {
        label: "Create your first sync",
        path: "/create-your-first-sync",
      },
    ],
  },
  {
    label: "Syncs",
    icon: SyncIcon,
    path: "/syncs",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Sync types and modes",
        path: "/types-and-modes",
      },
      {
        label: "Record matching",
        path: "/record-matching",
      },
      {
        label: "Field mapping",
        path: "/mapping-data",
      },
      {
        label: "Match boosting",
        path: "/match-boosting",
      },
      {
        label: "Sync schedules",
        nav: [
          {
            label: "Schedule syncs in the UI",
            path: "/schedule-sync-ui",
          },
          {
            label: "Schedule syncs with Sequences",
            path: "/schedule-sync-with-sequences",
          },
          {
            label: "Trigger syncs via dbt Cloud",
            href: "/extensions/dbt-cloud",
          },
          {
            label: "Trigger syncs via Fivetran",
            href: "/extensions/fivetran",
          },
          {
            label: "Trigger syncs via Airflow",
            href: "/extensions/airflow",
          },
          {
            label: "Trigger syncs via Dagster",
            href: "/extensions/dagster",
          },
          {
            label: "Trigger syncs via Prefect",
            href: "/extensions/prefect",
          },
          {
            label: "Trigger sync via API",
            href: "/developer-tools/api-guide",
          },
        ],
      },
      {
        label: "Troubleshooting syncs",
        nav: [
          {
            label: "Data types and casting",
            href: "/models/data-types-casting",
          },
          {
            label: "Live debugger",
            path: "/debugger",
          },
        ],
      },
      {
        label: "Additional features",
        nav: [
          {
            label: "Retry strategy",
            path: "/retries",
          },
          {
            label: "Alerting",
            path: "/alerting",
          },
          {
            label: "Lightning sync engine",
            path: "/lightning-sync-engine",
          },
          {
            label: "Warehouse sync logs",
            path: "/warehouse-sync-logs",
          },
          {
            label: "Data extraction",
            path: "/data-extraction",
          },
        ],
      },
    ],
  },
  {
    label: "Models",
    icon: ModelIcon,
    nav: [
      {
        label: "Overview",
        path: "/creating-models",
      },
      {
        label: "Data types and casting",
        path: "/data-types-casting",
      },
      {
        label: "Match boosting",
        path: "/match-boosting",
      },
      {
        label: "Modeling methods",
        nav: [
          {
            label: "SQL editor",
            path: "/sql-editor",
          },
          {
            label: "Table selector",
            path: "/table-selector",
          },
          {
            label: "dbt models and analyses",
            href: "/extensions/dbt-models",
          },
          {
            label: "Looker Looks",
            href: "/extensions/looker-models",
          },
          {
            label: "Sigma workbooks",
            href: "/extensions/sigma",
          },
        ],
      },
    ],
  },
  {
    label: "Hightouch Events",
    icon: EventsIcon,
    iconProps: {
      stroke: "none",
    },
    path: "/events",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Event tracking spec",
        path: "/event-spec",
      },
      {
        label: "Event streaming",
        path: "/event-streaming",
      },
      {
        label: "First party tracking",
        path: "/first-party-tracking",
      },
      {
        label: "Event Sources",
        nav: [
          {
            label: "Browser (Javascript)",
            path: "/sdks/browser",
          },
          {
            label: "Node.js",
            path: "/sdks/nodejs",
          },
          {
            label: "Ruby",
            path: "/sdks/ruby",
          },
          {
            label: "Go",
            path: "/sdks/go",
          },
          {
            label: "PHP",
            path: "/sdks/php",
          },
          {
            label: "Java",
            path: "/sdks/java",
          },
          {
            label: "Python",
            path: "/sdks/python",
          },
          {
            label: "iOS",
            path: "/sdks/ios",
          },
          {
            label: "Android",
            path: "/sdks/android",
          },
          {
            label: "React Native",
            path: "/sdks/react-native",
          },
          {
            label: "Flutter",
            path: "/sdks/flutter",
          },
          {
            label: "C# (.NET)",
            path: "/sdks/csharp",
          },
          {
            label: "HTTP",
            path: "/sdks/http",
          },
          {
            label: "Webhook",
            path: "/sources/webhook",
          },
          {
            label: "Kafka",
            path: "/sources/kafka",
          },
          {
            label: "Google PubSub",
            path: "/sources/google-pubsub",
          },
        ],
      },
      {
        label: "Warehouse destinations",
        nav: [
          {
            label: "Schema",
            path: "/warehouse-schema",
          },
          {
            label: "Error handling",
            path: "/error-handling",
          },
        ],
      },
      {
        label: "Contracts",
        nav: [
          {
            label: "Managing contracts",
            path: "/contracts/management",
          },
          {
            label: "Handling violations",
            path: "/contracts/violations",
          },
        ],
      },
      {
        label: "Functions",
        nav: [
          {
            label: "Overview",
            path: "/functions/overview",
          },
          {
            label: "Getting started",
            path: "/functions/getting-started",
          },
          {
            label: "Handling webhooks",
            path: "/functions/handling-webhooks",
          },
        ],
      },
      {
        label: "Consent",
        nav: [
          {
            label: "Consent Manager",
            path: "/consent/consent-manager",
          },
          {
            label: "OneTrust",
            path: "/consent/onetrust",
          },
        ],
      },
      {
        label: "FAQ",
        nav: [
          {
            label: "Migrating from Segment or RudderStack",
            path: "/faq/migrating-from-segment-or-rudderstack",
          },
        ],
      },
    ],
  },
  {
    label: "Identity Resolution",
    icon: IdentityResolutionIcon,
    iconProps: {
      stroke: "none",
    },
    private: true,
    path: "/identity-resolution",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Usage",
        path: "/usage",
      },
      {
        label: "Configuration",
        nav: [
          {
            label: "Model configuration",
            path: "/model-configuration",
          },
          {
            label: "Match rules",
            path: "/match-rules",
          },
        ],
      },
    ],
  },
  {
    label: "Customer Studio",
    icon: AudienceIcon,
    path: "/customer-studio",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Define data schema",
        path: "/schema",
      },
      {
        label: "Create audiences",
        path: "/usage",
      },
      {
        label: "Sync audiences",
        path: "/syncs",
      },
      {
        label: "Audience Insights",
        path: "/insights",
      },
      {
        label: "Journeys",
        path: "/journeys",
      },
      {
        label: "Additional features",
        nav: [
          {
            label: "Traits",
            path: "/traits",
          },
          {
            label: "Splits",
            path: "/splits",
          },
          {
            label: "Holdout Group Logs",
            path: "/holdout-group-logs",
          },
          {
            label: "Priority Lists",
            path: "/priority-lists",
          },
          {
            label: "Subsets",
            path: "/subsets",
          },
          {
            label: "Destination Rules",
            path: "/destination-rules",
          },
          {
            label: "Snapshots",
            path: "/audience-snapshots",
          },
        ],
      },
    ],
  },
  {
    label: "Campaign Intelligence",
    icon: ModelIcon,
    path: "/campaign-intelligence",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Campaigns",
        nav: [
          {
            label: "Ads dashboard",
            path: "/ads",
          },
          {
            label: "Emails dashboard",
            path: "/emails",
          },
        ],
      },
      {
        label: "Chart Types",
        nav: [
          {
            label: "Performance",
            path: "/performance",
          },
          {
            label: "Breakdowns",
            path: "/breakdowns",
          },
          {
            label: "Funnels",
            path: "/funnels",
          },
          {
            label: "Tables",
            path: "/tables",
          },
          {
            label: "Experiments",
            path: "/experiments",
          },
        ],
      },
      {
        label: "Measurement",
        nav: [
          {
            label: "Metrics",
            path: "/metrics",
          },
          {
            label: "Attribution",
            path: "/attribution",
          },
        ],
      },
      {
        label: "AI",
        nav: [
          {
            label: "Copilot",
            path: "/copilot",
          },
        ],
      },

      {
        label: "Schema",
        nav: [
          {
            label: "Interactions",
            path: "/interactions",
          },
          {
            label: "Assets",
            path: "/assets",
          },
          {
            label: "Messages",
            path: "/schema/messages",
          },
          {
            label: "Ads",
            path: "/schema/ads",
          },
        ],
      },
      {
        label: "Definitions",
        nav: [
          {
            label: "Charts",
            path: "/charts",
          },
        ],
      },
    ],
  },
  {
    label: "Sources",
    icon: SourceIcon,
  },
  {
    label: "Destinations",
    icon: DestinationIcon,
  },
  {
    label: "Match Booster",
    icon: SparkleIcon,
    iconProps: {
      stroke: "none",
    },
    path: "/match-booster",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Implementation",
        path: "/implementation",
      },
      {
        label: "Offerings",
        path: "/offerings",
      },
      {
        label: "FAQs",
        path: "/faqs",
      },
    ],
  },
  {
    label: "Extensions",
    icon: ExtensionIcon,
    nav: [
      {
        label: "Alerting and monitoring",
        nav: [
          {
            label: "Alerting overview",
            href: "/syncs/alerting",
          },
          {
            label: "PagerDuty",
            path: "/pagerduty",
          },
          {
            label: "Datadog",
            path: "/datadog",
          },
        ],
      },
      {
        label: "Model building",
        nav: [
          {
            label: "dbt models and analyses",
            path: "/dbt-models",
          },
          {
            label: "Looker Looks",
            path: "/looker-models",
          },
          {
            label: "Sigma workbooks",
            path: "/sigma",
          },
        ],
      },
      {
        label: "Sync scheduling",
        nav: [
          {
            label: "dbt Cloud",
            path: "/dbt-cloud",
          },
          {
            label: "Fivetran",
            path: "/fivetran",
          },
          {
            label: "Airflow",
            path: "/airflow",
          },
          {
            label: "Dagster",
            path: "/dagster",
          },
          {
            label: "Prefect",
            path: "/prefect",
          },
          {
            label: "Mage",
            path: "/mage",
          },
        ],
      },
      {
        label: "Version control and observability",
        nav: [
          {
            label: "Git version control",
            path: "/git-sync",
          },
          {
            label: "dbt observability layer",
            path: "/dbt-observability",
          },
        ],
      },
    ],
  },
  {
    label: "Workspace management",
    icon: WorkspaceIcon,
    path: "/workspace-management",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },
      {
        label: "Workspace organization",
        path: "/folders-filters",
      },
      {
        label: "Access management",
        nav: [
          {
            label: "Single sign-on",
            path: "/sso",
          },
          {
            label: "Role-Based controls",
            path: "/rbac",
          },
          {
            label: "Label-Based controls",
            path: "/lbac",
          },
        ],
      },
      {
        label: "Change management",
        nav: [
          {
            label: "Approval flows",
            path: "/approval-flows",
          },
          {
            label: "Environments",
            path: "/environments",
          },
          {
            label: "Audit logs",
            path: "/audit-logs",
          },
        ],
      },
    ],
  },
  {
    label: "Pricing",
    icon: ModelIcon,
    path: "/pricing",
    nav: [
      {
        label: "Self-serve pricing",
        path: "/ss-pricing",
      },
      {
        label: "Business tier pricing",
        path: "/bt-pricing",
      },
    ],
  },
  {
    label: "Security and compliance",
    icon: SecurityIcon,
    path: "/security",
    nav: [
      {
        label: "Overview",
        path: "/overview",
      },

      {
        label: "Storage",
        path: "/storage",
      },

      {
        label: "Regions",
        path: "/regions",
      },

      {
        label: "Networking",
        path: "/networking",
        nav: [
          {
            label: "Overview",
            path: "/networking",
          },
          {
            label: "IP addresses",
            path: "/networking#ip-addresses",
          },
          {
            label: "SSH tunneling",
            path: "/ssh-tunneling",
          },
        ],
      },

      {
        label: "Amazon Web Services",
        nav: [
          {
            label: "Overview",
            path: "/aws",
          },

          {
            label: "AWS PrivateLink",
            path: "/aws/privatelink",
          },
        ],
      },

      {
        label: "Google Cloud",
        nav: [
          {
            label: "Overview",
            path: "/gcp",
          },
          {
            label: "Private Service Connect",
            path: "/gcp/private-service-connect",
          },
        ],
      },

      {
        label: "Microsoft Azure",
        nav: [
          {
            label: "Overview",
            path: "/azure",
          },
        ],
      },
    ],
  },
  {
    label: "Developer Tools",
    path: "/developer-tools",
    icon: APIIcon,
    nav: [
      {
        label: "Personalization API",
        href: "/destinations/personalization-api",
      },
      {
        label: "REST API",
        nav: [
          {
            label: "API overview",
            path: "/api-guide",
          },
          {
            label: "API reference",
            href: "/api-reference",
          },
        ],
      },
      {
        label: "Command Line Interface",
        nav: [
          {
            label: "CLI overview",
            path: "/cli-guide",
          },
          {
            label: "CLI reference",
            path: "/cli-reference",
          },
        ],
      },
    ],
  },
];
