import { ChakraStyledOptions } from "@chakra-ui/react";


const sharpSansDisplayNo1 = `'Sharp Sans Display No 1', sans-serif`
const menlo = `Menlo, monospace`;

export const typography: ChakraStyledOptions = {
  textStyles: {
    paragraph: {
      my: 6,
      lineHeight: 1.6,
    },
    uppercase: {
      fontWeight: 'semibold',
      textTransform: "uppercase",
      letterSpacing: "0.08em",
    },
    h1: {
      fontFamily: 'heading',
      fontSize: { base: "3xl", md: "4xl" },
      fontWeight: "semibold",
      lineHeight: "110%",
      m: 0,
    },
    h2: {
      fontFamily: 'heading',
      fontSize: { base: "2xl", md: "3xl" },
      fontWeight: "semibold",
      lineHeight: "113%",
      mt: 12,
      mb: 6,
    },
    h3: {
      fontFamily: 'heading',
      fontSize: { base: "xl", md: "2xl" },
      fontWeight: "semibold",
      lineHeight: "113%",
      mt: 12,
      mb: 6,
    },
    h4: {
      fontFamily: 'heading',
      fontSize: { base: "lg", md: "xl" },
      fontWeight: "semibold",
      lineHeight: "113%",
      mt: 8,
      mb: 6,
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5625rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.5rem",
    "7xl": "4rem",
    "8xl": "5rem",
  },
  fonts: {
    body: sharpSansDisplayNo1,
    heading: sharpSansDisplayNo1,
    mono: menlo
  },
  lineHeights: {
    normal: "normal",
  },
  letterSpacings: {
    normal: "0",
  },
};
