import { Box, Button, Flex, SimpleGrid, Text,  } from "@chakra-ui/react";
import { ReactNode, FC, VFC } from "react";

import { CTALinks } from "components/cta-links";
import {
  StarIcon,
  ChatIcon,
  HelpIcon,
  PlusSquareIcon,
} from "ui/icons";
import { PylonButton } from "ui/pylon";

export const Footer: VFC = () => (
  <SimpleGrid columns={{ base: 1, lg: 3}} gap={{ base: 14, lg: 10}} mt="180px">
    <GetStarted />
    <Help />
    <Request />
  </SimpleGrid>
);

const GetStarted: VFC = () => {
  return (
    <Flex direction="column" flex={1} justify="space-between" alignSelf="stretch" gap={4}>
      <Box>
        <FooterHeading
          icon={(<StarIcon boxSize={6} stroke="text.primary" fill="transparent" />)}
          title="Ready to get started?"
        />
        <Text fontWeight="medium" fontSize="md">
          Jump right in or a book a demo. Your first destination is always free.
        </Text>
      </Box>
      <CTALinks primary="signup" flex={1} flexWrap="wrap" />
    </Flex>
  );
};

const Help: VFC = () => {
  return (
    <Flex direction="column" flex={1} justify="space-between" alignSelf="stretch" gap={4}>
      <Box>
        <FooterHeading icon={(<HelpIcon boxSize={6} fill="text.primary" />)} title="Need help?" />
        <Text fontWeight="medium" fontSize="md">
          Our team is relentlessly focused on your success. Don't hesitate to reach out!
        </Text>
      </Box>
      <PylonButton
        variant="tertiary"
        alignSelf="flex-start"
        justifySelf="flex-end"
        leftIcon={<ChatIcon boxSize={4} fill="text.primary" />}
        message="Hello, I have a question!"
      >
        Start a chat
      </PylonButton>
    </Flex>
  );
};

const Request: VFC = () => {
  return (
    <Flex direction="column" flex={1} justify="space-between" alignSelf="stretch" gap={4}>
      <Box>
        <FooterHeading icon={(<PlusSquareIcon boxSize={6} fill="text.primary" />)} title="Feature requests?" />
        <Text fontWeight="medium" fontSize="md">
          We'd love to hear your suggestions for integrations and
          other features.
        </Text>
      </Box>
      <PylonButton
        alignSelf="flex-start"
        variant="tertiary"
        message="Hello, I have a feature request!"
      >
        Request a feature
      </PylonButton>
    </Flex>
  );
};

interface FooterHeadingProps {
  icon: ReactNode;
  title: string;
}

const FooterHeading: FC<Readonly<FooterHeadingProps>> = ({ icon, title }) => (
  <Flex
    align="center"
    gap={2}
    pb={4}
    mb={4}
    borderBottom="1px solid"
    borderColor="gray.100"
  >
    {icon}
    <Text as="h2" fontWeight="semibold" fontSize="lg" whiteSpace="nowrap">
      {title}
    </Text>
  </Flex>
);
