import { Box, Flex, Text, Image as ThemedImage } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { FC } from "react";

import { Main } from "./main";

import { Feedback } from "components/feedback";
import { Footer } from "components/footer";
import { Head } from "components/head";
import { LastUpdated } from "components/last-updated";
import { MDX } from "components/mdx";
import { TableOfContents } from "components/table-of-contents/table-of-contents";
import { PageProps } from "pages/_app";
import { Heading, Info, PylonLink, Link, Warning } from "ui";

export const Docs: FC<Readonly<PageProps>> = ({
  children,
  meta,
  destinations,
  sources,
  extensions,
}) => {
  let { title, description, toc, lastUpdatedDate, icon } = meta;
  let pageDescription,
    status,
    warningTextStart,
    pylonText,
    warningTextFinish,
    vendorApiDocsUrl,
    vendorApiStatusUrl,
    deprecatedAt,
    sunsetAt;

  const path =
    typeof window !== "undefined" ? new URL(window.location.href).pathname : "";
  const isExtension = path.split("/").includes("extensions");
  const noIndexPages = [
    "/docs/pricing/mqrs",
    "/destinations/snowflake",
    "/campaign-intelligence/analytics",
    "/campaign-intelligence/breakdowns",
    "/campaign-intelligence/funnels",
    "/identity-resolution/overview",
    "/identity-resolution/usage",
    "/identity-resolution/model-configuration",
    "/identity-resolution/match-rules",
  ];
  const noIndex = noIndexPages.includes(path);

  if (meta.slug && destinations && sources) {
    const integrations = meta.type === "destination" ? destinations : sources;
    const integration = integrations.find((data) => data.slug === meta.slug);

    if (integration) {
      // Hacky renaming, but it's just for one page
      // If there's more we can do something akin to slugOverrides in the next.config.mjs
      if (integration.name === "Google Sheets - User Account") {
        title = "Google Sheets";
      } else {
        title = integration.name;
      }
      description = integration.shortPitch || integration.vendorAbout;
      pageDescription = description; //use longPitch eventually
      icon = integration.icon;
      status = integration.status;
      vendorApiDocsUrl = integration.vendorApiDocsUrl;
      vendorApiStatusUrl = integration.vendorApiStatusUrl;

      if (integration.status !== "released") {
        switch (status) {
          case "shadow":
            warningTextStart =
              "We're currently gauging interest for this destination. Please ";
            pylonText = `Hi, I would like to request a new destination. I want to sync data to ${title} in order to help the _____ team accomplish _____.`;
            warningTextFinish = " if you're interested.";
            break;
          case "coming_soon":
            warningTextStart =
              "We're currently gauging interest for this destination. Please ";
            pylonText = `Hi, I would like to request a new destination. I want to sync data to ${title} in order to help the _____ team accomplish _____.`;
            warningTextFinish = " if you're interested.";
            break;
          case "alpha":
            warningTextStart = "Please ";
            pylonText = `Hello, I am interesting in getting access to the ${title} ${meta.type}.`;
            warningTextFinish = " for access to this destination.";
            break;
          default:
            warningTextStart = null;
            pylonText = null;
            warningTextFinish = null;
        }
      }

      // It's a bit kludgy to have a special case for this, but we want to be able to show
      // the placeholder destination as a shadow destination (not shown in menus, search, &c.)
      // without showing the warnings that show by default for shadowed integrations.
      if (title === "sync-logs") {
        warningTextStart = null;
        pylonText = null;
        warningTextFinish = null;
      }
    }
  } else if (isExtension) {
    const splitPath = path.split("/");
    const extensionSlug = splitPath[splitPath.length - 1];
    const extension = extensions.find(
      (extension) => extension.slug === extensionSlug
    );
    icon = extension?.icon ?? null;
    vendorApiDocsUrl = extension.vendorApiDocsUrl;
    vendorApiStatusUrl = extension.vendorApiStatusUrl;
    pageDescription = extension.vendorAbout;
  }

  return (
    <>
      <Head title={title} description={description} noindex={noIndex} />
      <Flex>
        <Main>
          <Box as="article">
            <Flex align="center" mb={6}>
              {icon && (
                <ThemedImage
                  src={icon}
                  sx={{
                    height: "40px",
                    mr: 4,
                    objectFit: "contain",
                    flexShrink: 0,
                  }}
                />
              )}
              <Heading as="h1">{title}</Heading>
            </Flex>

            {pageDescription && (
              <Text fontWeight="semibold">{pageDescription}</Text>
            )}

            {(vendorApiDocsUrl || vendorApiStatusUrl) && (
              <Text mt={4}>
                View {title}&apos;s{" "}
                {vendorApiDocsUrl && (
                  <>
                    <Link
                      href={vendorApiDocsUrl}
                      fontWeight="semibold"
                      textDecoration="underline"
                      color="grass"
                    >
                      documentation
                    </Link>
                    {vendorApiStatusUrl ? " and " : ""}
                  </>
                )}
                {vendorApiStatusUrl && (
                  <Link
                    href={vendorApiStatusUrl}
                    fontWeight="semibold"
                    textDecoration="underline"
                    color="grass"
                  >
                    status page
                  </Link>
                )}
                .
              </Text>
            )}

            {status === "deprecated" && (
              <Warning>
                This integration has been deprecated
                {deprecatedAt
                  ? `as of ${format(parseISO(deprecatedAt), "MMMM d, yyyy")}`
                  : ""}
                . New syncs cannot be created, but existing syncs will continue
                to run
                {sunsetAt
                  ? ` until ${format(parseISO(sunsetAt), "MMMM d, yyyy")}`
                  : ""}
                .
              </Warning>
            )}

            {warningTextStart && (
              <Info>
                {warningTextStart}
                {pylonText && (
                  <PylonLink pylonText={pylonText} linkText="contact us" />
                )}
                {warningTextFinish}
              </Info>
            )}

            <MDX>{children}</MDX>
          </Box>

          <Footer />
        </Main>

        <Flex
          display={["none", "none", "none", "none", "flex", "flex"]}
          direction="column"
          pos="sticky"
          top={"calc(80px + 3rem)"}
          left={0}
          px={4}
          width="280px"
          flexShrink={0}
          maxHeight="80vh"
        >
          <LastUpdated date={lastUpdatedDate} />
          <TableOfContents toc={toc} />
          <Feedback />
        </Flex>
      </Flex>
    </>
  );
};
