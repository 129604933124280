// Color palette from https://www.figma.com/file/rLsSTygVap7zagoErmncwm/Styles
const colors = {
  gray: {
    "50": "#FBFCFD",
    "100": "#F2F4F7",
    "200": "#E8EDF2",
    "300": "#DBE1E8",
    "400": "#BBC5D0",
    "500": "#9AA6B2",
    "600": "#697786",
    "700": "#4E5C6B",
    "800": "#364452",
    "900": "#252D36",
  },
  danger: {
    "50": "#fffbfa",
    "100": "#ffeceb",
    "200": "#ffe2e0",
    "300": "#f9c8c4",
    "400": "#f69993",
    "500": "#d25046",
    "600": "#a8261f",
    "700": "#7c140c",
    "800": "#6b1109",
    "900": "#411008",
  },
  success: {
    "50": "#f5fffa",
    "100": "#ecfef5",
    "200": "#b8f2d4",
    "300": "#a0e8c2",
    "400": "#79d5a6",
    "500": "#4fc26a",
    "600": "#258e40",
    "700": "#006a34",
    "800": "#004822",
    "900": "#002c16",
  },
};

const themeColors = {
  ...colors,
  text: {
    primary: colors.gray["900"],
    secondary: colors.gray["600"],
    placeholder: colors.gray["500"],
  },
  base: {
    border: colors.gray["300"],
    divider: colors.gray["200"],
    background: colors.gray["100"],
    lightBackground: colors.gray["50"],
  },

  forest: "#03484D",
  lightspeed: "#CAF23A",
  lightspeedLight: "#D5F378",
  lightspeedHighlight: "#D4FF39",
  space: "#222F51",
  spaceLight: "#314475",
  ocean: "#336BFB",
  oceanLight: "#5384FF",
  oceanHighlight: "#8DE4FF",
  grass: "#00A341",
  grassLight: "#00B849",
  grassHighlight: "#6FFD91",
  peridot: "#F1F6D4",
  lilac: "#F1E7FC",
  plum: "#633CC6",
  plumLight: "#7A55D9",
  plumHighlight: "#E394FF",
  electric: "#B979FF",
  sky: "#E2EDF6",
  blue: "#A3BCFE",
  avocado: "#E8F8EC",
}

export default themeColors;

export type Colors = typeof themeColors;