import { Flex, useBoolean } from "@chakra-ui/react";
import { FC } from "react";

import { Extension } from "api/get-extensions";
import { Integration } from "api/get-integrations";
import { User } from "api/get-user";
import { Nav } from "components/nav";
import { Topbar } from "components/topbar";

type Props = {
  loadingUser?: boolean;
  user?: User;
  destinations: Integration[];
  sources: Integration[];
  extensions: Extension[];
};

export const Layout: FC<Readonly<Props>> = ({
  children,
  loadingUser,
  ...props
}) => {
  const [mobileNavOpen, { toggle: toggleMobileNav }] = useBoolean(false);

  if (!props.destinations || !props.sources) {
    return (
      <Flex
        as="main"
        pt={24}
        pb={16}
        direction="column"
        flex={1}
        width="100%"
        maxWidth="1280px"
        mx="auto"
      >
        {children}
      </Flex>
    );
  }

  return (
    <>
      <Topbar
        {...props}
        toggleNav={toggleMobileNav}
        navOpen={mobileNavOpen}
        loadingUser={loadingUser}
      />

      {mobileNavOpen && (
        <Flex
          display={["flex", "flex", "none"]}
          bg="white"
          direction="column"
          pos="sticky"
          top="60px"
          left={0}
          height="calc(100vh - 60px)"
          width="100%"
          pb={6}
          overflow="auto"
        >
          <Nav {...props} toggle={toggleMobileNav} />
        </Flex>
      )}

      <Flex mx="auto" maxWidth="1600px" width="100%">
        <Flex
          display={["none", "none", "flex"]}
          id="nav"
          bg="white"
          direction="column"
          pos="sticky"
          top="80px"
          left={0}
          height="calc(100vh - 80px)"
          width="320px"
          flexShrink={0}
          overflow="auto"
          overscrollBehavior="contain"
          borderRight="1px rgba(212, 217, 223, 1) solid"
        >
          <Nav {...props} />
        </Flex>

        <Flex
          direction="column"
          pt={6}
          pb={16}
          width="100%"
          display={mobileNavOpen ? "none" : "flex"}
        >
          {children}
        </Flex>
      </Flex>
    </>
  );
};
