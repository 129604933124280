import { useRouter } from "next/router";
import { VFC, useEffect } from "react";

export const HtEvents: VFC = () => {
  const router = useRouter();

  const load = async () => {
    const { HtEventsBrowser } = await import("@ht-sdks/events-sdk-js-browser");
    window.htevents = HtEventsBrowser.load(
      { writeKey: process.env.NEXT_PUBLIC_HT_EVENTS_WRITE_KEY },
      {
        apiHost: process.env.NEXT_PUBLIC_HT_EVENTS_DATA_PLANE_URL,
        batching: false,
      }
    );
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (window.htevents) {
        window.htevents.page();
      }
    };

    handleRouteChange();
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return null;
};
