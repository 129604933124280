export const track = (...args) => {
  if (
    typeof window.htevents === "object" &&
    typeof window.htevents.track === "function"
  ) {
    window.htevents.track(...args);
  }
};

export const identify = (...args) => {
  if (
    typeof window.htevents === "object" &&
    typeof window.htevents.identify === "function"
  ) {
    window.htevents.identify(...args);
  }
};
