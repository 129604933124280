import { Button, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { VFC } from "react";

import { openPylon } from "components/vendor/pylon";
import { track } from "lib/analytics";

type PylonButtonProps = {
  message?: string;
} & React.ComponentProps<typeof Button>;

export const PylonButton: VFC<PylonButtonProps> = ({ message, children, ...props }) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button
        {...props}
        onClick={() => {
          track("Open Pylon", {
            page: window.location.pathname,
          });
          
          const opened = openPylon(`${message}`);

          // If pylon is not available (likely because user if not logged in), 
          // fallback to opening a support modal with a contact email
          if (!opened) {
            onOpen();
          }
        }}
      >
        {children}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Get in touch!</ModalHeader>
          <ModalBody>
            <Link
              href="https://app.hightouch.com/login"
              color="ocean"
            >
              Sign in
            </Link>{" "}
            to chat with a member of our team, or reach out to us via email at{" "}
            <Link href="mailto:friends@hightouch.com" color="ocean">friends@hightouch.com</Link>.
          </ModalBody>
          <ModalFooter>
            <Button variant="grass" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

type PylonLinkProps = {
  pylonText?: string;
  linkText?: string;
};
  
export const PylonLink: VFC<PylonLinkProps> = ({ pylonText, linkText }) => {
  return (
    <PylonButton
      color="grass"
      textDecoration="underline"
      fontWeight="medium"
      verticalAlign="unset"
      message={pylonText}    
    >
      {linkText}
    </PylonButton>
  );
};
  