import * as Sentry from "@sentry/nextjs";
import { GraphQLClient } from "graphql-request";
import LDClient, { LDContext } from "launchdarkly-js-client-sdk";

export type User = {
  id: string;
  private: boolean;
};

const endpoint = process.env.NEXT_PUBLIC_API_URL;

const client = new GraphQLClient(endpoint, {
  method: "POST",
  credentials: "include",
  headers: {
    "x-hasura-role": "user",
  },
});

const GET_USERS = `{
  getCurrentUserV2 {
    email
    id
    name
    avatar_json
    current_workspace_id
    pylon_email_hash
  }
}`;

export const getUser = async (): Promise<User | null> => {
  try {
    const data = await client.request(GET_USERS);

    const currentUser = data?.getCurrentUserV2;

    if (!currentUser) {
      return null;
    }

    const ldConfig: LDContext = {
      key: `user-${currentUser.id.toString()}`,
      name: currentUser.name,
      email: currentUser.email,
      custom: {
        workspaceId: currentUser.current_workspace_id
          ? Number(currentUser.current_workspace_id)
          : undefined,
      },
    };

    const ldClient = LDClient.initialize(
      process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
      ldConfig
    );
    await ldClient.waitUntilReady();

    const showPrivatePages = ldClient.variation(
      "docs-show-private-pages",
      false
    );
    currentUser.private = showPrivatePages;

    return currentUser;
  } catch (error) {
    Sentry.captureException(error);
  }

  return null;
};
