import { type ComponentStyleConfig, theme } from "@chakra-ui/theme";

export const Tabs: ComponentStyleConfig = {
  ...theme.components.Tabs,
  baseStyle: {
    ...theme.components.Tabs.baseStyle,
    _focus: {
      outline: "auto",
    },
  },
};
