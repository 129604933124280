import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Input } from "@chakra-ui/react";
import { connectSearchBox } from "react-instantsearch-dom";

import { SearchIcon } from "ui/icons";

export const SearchInput = connectSearchBox(({ refine, currentRefinement }) => {
  return (
    <Flex width="100%" px={4} height="60px" align="center">
      <SearchIcon fill="grass" boxSize={7} />
      <Input
        flex={1}
        autoFocus
        spellCheck={false}
        border="none"
        outline="none"
        fontSize="lg"
        placeholder="Search documentation..."
        value={currentRefinement}
        onChange={(e) => {
          refine(e.currentTarget.value);
        }}
        sx={{ caretColor: "var(--theme-colors-grass)" }}
      />
      <CloseIcon
        visibility={currentRefinement ? "visible" : "hidden"}
        pointerEvents={currentRefinement ? "all" : "none"}
        as="button"
        cursor="pointer"
        ml="auto"
        boxSize={3}
        color="gray.300"
        onClick={() => {
          refine("");
        }}
      />
    </Flex>
  );
});
