import { ChakraProps, Link as ThemedLink } from "@chakra-ui/react";
import NextLink from "next/link";
import { FC, forwardRef, MouseEventHandler, Ref, RefAttributes } from "react";

export type LinkProps = {
  href?: string;
  newTab?: boolean;
  onClick?: MouseEventHandler;
  onMouseMove?: MouseEventHandler;
} & ChakraProps &
  RefAttributes<any>;

export const Link: FC<Readonly<LinkProps>> = forwardRef(
  ({ href, newTab, children, ...props }, ref: Ref<any>) => {
    if (href?.startsWith("/")) {
      return (
        <NextLink passHref href={href}>
          <ThemedLink
            ref={ref}
            target={newTab ? "_blank" : undefined}
            rel={newTab ? "noreferrer noopener" : undefined}
            _hover={{ color: "grassLight" }}
            {...props}
          >
            {children}
          </ThemedLink>
        </NextLink>
      );
    }

    return (
      <ThemedLink
        ref={ref}
        href={href}
        target={newTab ? "_blank" : undefined}
        rel={newTab ? "noreferrer noopener" : undefined}
        _hover={{ color: "grassLight" }}
        {...props}
      >
        {children}
      </ThemedLink>
    );
  }
);

Link.displayName = "Link";
